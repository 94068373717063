import IInfo from "../../../model/firestore/Info";
import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {useGeneralStyles} from "../../../styles/GeneralStyles";
import AppButton from "../buttons/AppButton";
import {languagesToString, tt} from "../../../core/Localization";
import {Box, createStyles, Flex, Group, Table} from "@mantine/core";
import {DateTime} from "luxon";
import {countInfos, infoTypeToString, kInfosPageSize, subscribeInfos} from "../../../service/InfoService";
import {appTypesToString} from "../../../service/AppVersionService";
import AppIconButton from "../buttons/AppIconButton";
import YesNoBooleanLabel from "../text/YesNoBooleanLabel";
import {IconCopy, IconEdit, IconMinus, IconPlus, IconTrash} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    body: {
        wordBreak: 'break-word',
    },
}));

export interface IInfosTableProps {
    onEdit?: (info: IInfo) => void;
    onDelete?: (info: IInfo) => void;
    onDuplicate?: (info: IInfo) => void;
}

/**
 * Table component for displaying Info list.
 */
export default function InfosTable(props: IInfosTableProps) {
    const { onEdit, onDelete, onDuplicate } = props;

    const appDataContext = useContext(AppDataContext);
    const { language } = appDataContext;

    const [infos, setInfos] = useState<IInfo[]>([]);
    const [loadPages, setLoadPages] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);

    const { classes } = useStyles();
    const { classes: generalClasses } = useGeneralStyles();

    const totalRequestCount = kInfosPageSize * loadPages;
    const canLoadLess = loadPages > 1;
    const canLoadMore = infos.length >= totalRequestCount;

    useEffect(() => {
        const unsubscribe = subscribeInfos((snapshot) => {
            const newInfos: IInfo[] = [];

            snapshot.forEach((doc) => {
                const data = doc.data();
                const info: IInfo = data as any;

                info.id = doc.id;

                newInfos.push(info);
            });

            setInfos(newInfos);

            setTimeout(() => {
                countInfos()
                    .then((count) => {
                        setTotal(count);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }, 300);
        }, loadPages);

        return () => {
            unsubscribe();
        };
    }, [loadPages]);

    /**
     * Load less Infos.
     */
    const onLoadLess = () => {
        if (canLoadLess) {
            setLoadPages(loadPages - 1);
        }
    };

    /**
     * Load more Infos.
     */
    const onLoadMore = () => {
        if (canLoadMore) {
            setLoadPages(loadPages + 1);
        }
    };

    const rowsJSX = infos.map((info) => {
        const date = DateTime.fromMillis(info.createdAt);

        return (
            <tr key={info.id}>
                <td>{appTypesToString(info.apps)}</td>
                <td>{infoTypeToString(info.type)}</td>
                <td>{languagesToString(info.languages).join(', ')}</td>
                <td>{info.title}</td>
                <td>
                    <YesNoBooleanLabel
                        value={info.enabled}
                    />
                </td>
                <td>
                    <YesNoBooleanLabel
                        value={info.debugOnly}
                    />
                </td>
                <td>
                    <YesNoBooleanLabel
                        value={info.authUsersOnly || false}
                    />
                </td>
                <td>{date.toLocaleString(DateTime.DATETIME_MED, {locale: language})}</td>
                <td align="right">
                    <Flex
                        justify="flex-end"
                    >
                        <AppIconButton
                            onClick={() => onEdit && onEdit(info)}
                            tooltip={tt('common.table.button.edit')}
                        >
                            <IconEdit/>
                        </AppIconButton>

                        <AppIconButton
                            onClick={() => onDuplicate && onDuplicate(info)}
                            tooltip={tt('common.table.button.duplicate')}
                        >
                            <IconCopy/>
                        </AppIconButton>

                        <AppIconButton
                            onClick={() => onDelete && onDelete(info)}
                            tooltip={tt('common.table.button.delete')}
                        >
                            <IconTrash color="red"/>
                        </AppIconButton>
                    </Flex>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
            <thead>
                <tr>
                    <th>{tt('infosTable.header.app')}</th>
                    <th>{tt('infosTable.header.type')}</th>
                    <th>{tt('infosTable.header.languages')}</th>
                    <th>{tt('infosTable.header.title')}</th>
                    <th>{tt('infosTable.header.enabled')}</th>
                    <th>{tt('infosTable.header.debugOnly')}</th>
                    <th>{tt('infosTable.header.authUsersOnly')}</th>
                    <th>{tt('infosTable.header.createdAt')}</th>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', total)}
                    </Box>
                </tr>
                </thead>

                <tbody
                    className={classes.body}
                >
                    {rowsJSX}
                </tbody>

                <tfoot>
                <tr>
                    <th>{tt('infosTable.header.app')}</th>
                    <th>{tt('infosTable.header.type')}</th>
                    <th>{tt('infosTable.header.languages')}</th>
                    <th>{tt('infosTable.header.title')}</th>
                    <th>{tt('infosTable.header.enabled')}</th>
                    <th>{tt('infosTable.header.debugOnly')}</th>
                    <th>{tt('infosTable.header.authUsersOnly')}</th>
                    <th>{tt('infosTable.header.createdAt')}</th>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', total)}
                    </Box>
                </tr>
                </tfoot>
            </Table>

            <Group w="100%" position="right">
                <AppButton
                    mb="sm"
                    mr="sm"
                    onClick={onLoadLess}
                    leftIcon={<IconMinus />}
                    disabled={!canLoadLess}
                >
                    {tt('common.table.button.loadLess')}
                </AppButton>

                <AppButton
                    mb="sm"
                    onClick={onLoadMore}
                    leftIcon={<IconPlus />}
                    disabled={!canLoadMore}
                >
                    {tt('common.table.button.loadMore')}
                </AppButton>
            </Group>
        </>
    );
}

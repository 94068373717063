import {Route, Routes} from "react-router-dom";
import NoMatchScreen, {kNoMatchRoute} from "../ui/screens/NoMatchScreen";
import SignInScreen, {kSignInRoute} from "../ui/screens/authorization/SignInScreen";
import ProtectedRoute from "./ProtectedRoute";
import DashboardScreen, {kDashboardRoute} from "../ui/screens/dashboard/DashboardScreen";
import InfoListScreen, {kInfoListRoute} from "../ui/screens/infos/InfoListScreen";
import AppVersionListScreen, {kAppVersionsRoute} from "../ui/screens/appVersions/AppVersionListScreen";
import SignOutScreen, {kSignOutRoute} from "../ui/screens/authorization/SignOutScreen";
import UserListScreen, {kUserListRoute} from "../ui/screens/users/UserListScreen";
import CompanyListScreen, {kCompanyListRoute} from "../ui/screens/companies/CompanyListScreen";
import SubscriptionListScreen, {kSubscriptionListRoute} from "../ui/screens/companies/SubscriptionListScreen";
import CompanySubscriptionsScreen, { kCompanySubscriptionsRoute } from "../ui/screens/companies/CompanySubscriptionsScreen";
import TimotySettingsScreen, {kTimotySettingsRoute} from "../ui/screens/timotySettings/TimotySettingsScreen";
import UserDeleteAccountRequestListScreen, {kUserDeleteAccountRequestListRoute} from "../ui/screens/users/UserDeleteAccountRequestListScreen";

const kProtectedRedirect = kSignInRoute;

/**
 * Main App routing component, uses Browser history based routing.
 */
export default function Router() {
    return (
        <Routes>
            <Route path={kSignInRoute} element={<SignInScreen/>}/>
            <Route path={kSignOutRoute} element={<SignOutScreen/>}/>

            <Route path={kDashboardRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <DashboardScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kAppVersionsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <AppVersionListScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kInfoListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <InfoListScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kUserListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <UserListScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kUserDeleteAccountRequestListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <UserDeleteAccountRequestListScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kCompanyListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <CompanyListScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kSubscriptionListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <SubscriptionListScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kCompanySubscriptionsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <CompanySubscriptionsScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kTimotySettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <TimotySettingsScreen/>
                </ProtectedRoute>
            }/>

            <Route path={kNoMatchRoute} element={<NoMatchScreen/>}/>
        </Routes>
    );
}

import {createStyles, Grid, MultiSelect, SelectItem, TextInput} from "@mantine/core";
import {kLargeDesktop} from "../../../styles/AppThemeProcessor";
import {useContext, useEffect, useState} from "react";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import {tt} from "../../../core/Localization";
import CompaniesTable from "../../components/companies/CompaniesTable";
import {AppDataContext} from "../../../AppData";
import {useSearchParams} from "react-router-dom";
import AppButton from "../../components/buttons/AppButton";
import {processQueryError} from "../../../service/ErrorService";
import {IndustryResponse} from "../../../generated/graphql/graphql";
import {IconTrashX} from "@tabler/icons-react";

export const kCompanyListRoute = '/companies';

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
    resetFilter: {
        marginTop: 25,
    },
}));

/**
 * Screen component for list of Company.
 */
export default function CompanyListScreen() {
    const appDataContext = useContext(AppDataContext);
    const { setTitle, language } = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiGet, restApiPost } = restApiClientContext;

    const { classes } = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    const [search, setSearch] = useState<string>(searchParams.get('companies-search') || '');
    const [companyId, setCompanyId] = useState<string>(searchParams.get('companies-companyId') || '');
    const paramsIndustryIds = searchParams.get('companies-industryIds') || '';
    const [industryIds, setIndustryIds] = useState<string[]>(paramsIndustryIds.length > 0 ? paramsIndustryIds.split(',') : []);
    const [industryOptions, setIndustryOptions] = useState<SelectItem[]>([]);

    useEffect(() => {
        setTitle(tt('companyList.screen.title'));
    }, []);

    useEffect(() => {
        restApiGet({
            uri: '/industry/search',
            onData: (data) => {
                if (data) {
                    const options: SelectItem[] = [];
                    data.content.forEach((industry: IndustryResponse) => {
                        options.push({
                            value: industry.id.toString(),
                            label: tt(industry.translationKey),
                        });
                    });
                    setIndustryOptions(options);
                } else {
                    setIndustryOptions([]);
                }
            },
            onError: (error) => processQueryError(appDataContext, error),
        });
    }, [language]);

    /**
     * Set filter state values.
     */
    const onResetFilter = () => {
        setSearch('');
        setCompanyId('');
        setIndustryIds([]);
    };

    return (
        <>
            <ScreenContent>
                <AppPaper
                    className={classes.paper}
                    mb="md"
                    centerContent={false}
                >
                    <Grid>
                        <Grid.Col span="auto">
                            <TextInput
                                label={tt('companyList.search')}
                                placeholder={tt('companyList.search.placeholder')}
                                mb="sm"
                                value={search}
                                onChange={(event) => setSearch(event.currentTarget.value)}
                            />
                        </Grid.Col>

                        <Grid.Col span="content">
                            <AppButton
                                className={classes.resetFilter}
                                mb="sm"
                                onClick={onResetFilter}
                                leftIcon={<IconTrashX/>}
                            >
                                {tt('common.table.button.resetFilter')}
                            </AppButton>
                        </Grid.Col>

                        <Grid.Col span={12} p={0} />

                        <Grid.Col span={4}>
                            <TextInput
                                label={tt('companyList.companyId')}
                                placeholder={tt('companyList.companyId.placeholder')}
                                mb="sm"
                                value={companyId}
                                onChange={(event) => setCompanyId(event.currentTarget.value)}
                            />
                        </Grid.Col>

                        <Grid.Col span={4}>
                            <MultiSelect
                                data={industryOptions}
                                label={tt('companyList.industryIds')}
                                placeholder={tt('companyList.industryIds.placeholder')}
                                value={industryIds}
                                onChange={setIndustryIds}
                            />
                        </Grid.Col>
                    </Grid>
                </AppPaper>

                <AppPaper
                    className={classes.paper}
                >
                    <CompaniesTable
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        search={search}
                        companyId={companyId}
                        industryIds={industryIds}
                    />
                </AppPaper>
            </ScreenContent>
        </>
    );
}

import {Box, createStyles, Flex, Loader, Table} from "@mantine/core";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {AppDataContext} from "../../../AppData";
import {useGeneralStyles} from "../../../styles/GeneralStyles";
import {tt} from "../../../core/Localization";
import TableHeadCell from "../tables/TableHeadCell";
import PaginationSection from "../tables/PaginationSection";
import {
    AdminDeleteAccountRequestJoinedUserResponsePage,
    AdminGetUserDeleteRequestsInput
} from "../../../generated/graphql/graphql";
import {kTopicUsers} from "../../../core/constants";
import {processQueryError} from "../../../service/ErrorService";
import {userFullName} from "../../../service/UserService";
import YesNoBooleanLabel from "../text/YesNoBooleanLabel";
import {DateTime} from "luxon";

const useStyles = createStyles((theme) => ({
    photoUrl: {
        width: 48,
        height: 'auto',
    },
    body: {
        wordBreak: 'break-word',
        '.normal': {
            wordBreak: 'normal',
        },
    },
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
    orange: {
        color: 'orange',
    }
}));

const kUserDeleteAccountRequestsPageSize = 10;

export interface IUserDeleteAccountRequestsTableProps {
    search?: string;
}

/**
 * Table component for displaying User Delete Account Request list.
 */
export default function UserDeleteAccountRequestsTable(props: IUserDeleteAccountRequestsTableProps) {
    const {search} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();
    const {classes: generalClasses} = useGeneralStyles();

    const [sortDesc, setSortDesc] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('id');

    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AdminDeleteAccountRequestJoinedUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicUsers,
            {
                uri: '/admin/user/delete-request/search',
                params: {
                    search,
                    page,
                    pageSize: kUserDeleteAccountRequestsPageSize,
                    sortDesc,
                    sortBy,
                    includeUserFirebaseRecords: true,
                } as AdminGetUserDeleteRequestsInput,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            () => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [page, search, sortDesc, sortBy]);

    useEffect(() => {
        if (data) {
            setTotal(data.total);
        } else {
            setTotal(0);
        }
    }, [data]);

    const [rowsJSX, setRowsJSX] = useState<ReactNode[]>([]);
    useEffect(() => {
        const newItems: ReactNode[] = [];

        if (loading) {
            newItems.push(
                <tr key="loading">
                    <td colSpan={8}>
                        <Flex justify="center">
                            <Loader size="md"/>
                        </Flex>
                    </td>
                </tr>
            );
        }

        newItems.push(...data ? data.content.map((request) => {
            const userRecord = data.userFirebaseRecords!.find((userRecord) => userRecord.uid === request.user.firebaseId);

            const firebaseJSX = (
                <>
                    {request.user.firebaseId}

                    {userRecord ? (
                        <>
                            <br/>

                            {userRecord.disabled ? (
                                <span
                                    className={classes.orange}>{tt('userDeleteAccountRequests.firebase.disabled')}</span>
                            ) : (
                                <span
                                    className={classes.green}>{tt('userDeleteAccountRequests.firebase.enabled')}</span>
                            )}
                        </>
                    ) : (
                        <>
                            <br/>

                            <span className={classes.red}>{tt('userDeleteAccountRequests.firebase.notExists')}</span>
                        </>
                    )}
                </>
            );

            return (
                <tr key={request.id}>
                    <td className="normal">{request.id}</td>
                    <td>{firebaseJSX}</td>
                    <td>{request.user.email}</td>
                    <td>{userFullName(request.user.name, request.user.surname)}</td>
                    <td>
                        {request.createdAt ? DateTime.fromMillis(request.createdAt).toLocaleString(DateTime.DATETIME_MED, {locale: language}) : null}
                    </td>
                    <td>
                        <YesNoBooleanLabel
                            value={request.fulfilled}
                        />
                    </td>
                    <td>
                        {request.fulfilledAt ? DateTime.fromMillis(request.fulfilledAt).toLocaleString(DateTime.DATETIME_MED, {locale: language}) : null}
                    </td>
                    <td align="right">
                        <Flex
                            justify="flex-end"
                        >

                        </Flex>
                    </td>
                </tr>
            );
        }) : []);

        setRowsJSX(newItems);
    }, [data, loading, language]);

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
                <thead>
                <tr>
                    <TableHeadCell
                        sortKey="id"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('common.table.header.id')}
                    </TableHeadCell>
                    <th>{tt('userDeleteAccountRequests.header.firebaseId')}</th>
                    <TableHeadCell
                        sortKey="user.email"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('userDeleteAccountRequests.header.email')}
                    </TableHeadCell>
                    <TableHeadCell
                        sortKey="user.surname"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('userDeleteAccountRequests.header.fullName')}
                    </TableHeadCell>
                    <TableHeadCell
                        sortKey="createdAt"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('userDeleteAccountRequests.header.createdAt')}
                    </TableHeadCell>
                    <TableHeadCell
                        sortKey="fulfilled"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('userDeleteAccountRequests.header.fulfilled')}
                    </TableHeadCell>
                    <TableHeadCell
                        sortKey="fulfilledAt"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('userDeleteAccountRequests.header.fulfilledAt')}
                    </TableHeadCell>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', data?.total || 0)}
                    </Box>
                </tr>
                </thead>

                <tbody
                    className={classes.body}
                >
                {rowsJSX}
                </tbody>

                <tfoot>

                </tfoot>
            </Table>

            <PaginationSection
                total={total}
                page={page}
                setPage={setPage}
                pageSize={kUserDeleteAccountRequestsPageSize}
            />
        </>
    );
}

import {createStyles, TextInput} from "@mantine/core";
import {kLargeDesktop} from "../../../styles/AppThemeProcessor";
import {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import UserDeleteAccountRequestsTable from "../../components/users/UserDeleteAccountRequestsTable";

export const kUserDeleteAccountRequestListRoute = '/users/delete-account-requests';

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
}));

/**
 * Screen component for list of User delete account requests.
 */
export default function UserDeleteAccountRequestListScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle} = appDataContext;

    const {classes} = useStyles();

    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        setTitle(tt('userDeleteAccountRequestList.screen.title'));
    }, []);

    return (
        <ScreenContent>
            <AppPaper
                className={classes.paper}
                mb="md"
                centerContent={false}
            >
                <TextInput
                    label={tt('userDeleteAccountRequestList.search')}
                    placeholder={tt('userDeleteAccountRequestList.search.placeholder')}
                    mb="sm"
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                />
            </AppPaper>

            <AppPaper
                className={classes.paper}
            >
                <UserDeleteAccountRequestsTable
                    search={search}
                />
            </AppPaper>
        </ScreenContent>
    );
}

import { useContext, useEffect, useState } from "react";
import { FetchPolicy, RestApiClientContext } from "../../../core/RestApiProvider";
import ScreenContent from "../../components/screens/ScreenContent";
import { AppDataContext } from "../../../AppData";
import { tt } from "../../../core/Localization";
import {createStyles, Group, TextInput} from "@mantine/core";
import AppPaper from "../../components/screens/AppPaper";
import SubscriptionsTable from "../../components/companies/SubscriptionsTable";
import { useDisclosure } from "@mantine/hooks";
import { AdminDeleteSubscriptionTemplateInput, AdminSaveSubscriptionTemplateInput, SubscriptionResponse } from "../../../generated/graphql/graphql";
import AppButton from "../../components/buttons/AppButton";
import SubscriptionTemplateEditModal from "../../components/modals/companies/SubscriptionTemplateEditModal";
import { processMutationError } from "../../../service/ErrorService";
import { SuccessToast } from "../../../service/ToastService";
import { HideConfirmActionModal, SetConfirmActionModal } from "../../components/modals/AppModals";
import {kLargeDesktop} from "../../../styles/AppThemeProcessor";
import {IconPlus} from "@tabler/icons-react";

export const kSubscriptionListRoute = '/subscriptions';

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
}));

/**
 * Screen component for list of Subscription.
 * These are templates, not those that are actually used.
 */
export default function SubscriptionListScreen() {
    const appDataContext = useContext(AppDataContext);
    const { setTitle } = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiPost, restApiDelete } = restApiClientContext;

    const { classes } = useStyles();

    const [search, setSearch] = useState<string>('');
    const [opened, { open, close }] = useDisclosure(false);
    const [editSubscription, setEditSubscription] = useState<SubscriptionResponse | null>(null);

    useEffect(() => {
        setTitle(tt('subscriptionList.screen.title'));
    }, []);

    /**
     * OnCreate opens modal for creating new Subscription template.
     */
    const onCreate = () => {
        setEditSubscription(null);
        open();
    };

    /**
     * OnEdit opens modal for editing app version.
     */
    const onEdit = (subscription: SubscriptionResponse) => {
        setEditSubscription(subscription);
        open();
    };

    const [saveLoading, setSaveLoading] = useState(false);
    /**
     * Save Subscription template to BE.
     */
    const onSubmit = (input: AdminSaveSubscriptionTemplateInput) => {
        setSaveLoading(true);

        restApiPost({
            uri: '/admin/subscription/save-template',
            params: input,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setSaveLoading,
            onData: (data: SubscriptionResponse) => {
                if (data) {
                    close();

                    SuccessToast(
                        editSubscription ? tt('subscriptionEditModal.save.success') : tt('subscriptionEditModal.create.success'),
                    );
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    const [deleteLoading, setDeleteLoading] = useState(false);
    /**
     * Delete Subscription template to BE.
     */
    const onDelete = (subscription: SubscriptionResponse) => {
        SetConfirmActionModal(appDataContext, {
            onConfirm: () => {
                setDeleteLoading(true);

                HideConfirmActionModal(appDataContext);

                restApiDelete({
                    uri: '/admin/subscription/template',
                    params: {
                        id: subscription.id,
                    } as AdminDeleteSubscriptionTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setDeleteLoading,
                    onData: (data: SubscriptionResponse) => {
                        if (data) {
                            SuccessToast(tt('subscriptionEditModal.delete.success'));
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            },
        });
    };

    //TODO subscribe to delete events

    return (
        <>
            <ScreenContent>
                <AppPaper
                    className={classes.paper}
                    mb="md"
                    centerContent={false}
                >
                    <TextInput
                        label={tt('subscriptionList.search')}
                        placeholder={tt('subscriptionList.search.placeholder')}
                        mb="sm"
                        value={search}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                </AppPaper>

                <AppPaper
                    className={classes.paper}
                >
                    <Group w="100%" position="right">
                        <AppButton
                            mb="sm"
                            onClick={onCreate}
                            leftIcon={<IconPlus />}
                        >
                            {tt('subscriptionEditModal.create')}
                        </AppButton>
                    </Group>

                    <SubscriptionsTable
                        search={search}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </AppPaper>
            </ScreenContent>

            <SubscriptionTemplateEditModal
                opened={opened}
                close={close}
                existing={editSubscription}
                onSubmit={onSubmit}
                loading={saveLoading}
            />
        </>
    );
}

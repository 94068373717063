import {Box, createStyles, Flex, Table} from "@mantine/core";
import {useGeneralStyles} from "../../../styles/GeneralStyles";
import {ReactNode, useContext, useEffect, useState} from "react";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {languageToString, tt} from "../../../core/Localization";
import {AdminGetUsersInput, AdminUserResponsePage, UserResponse} from "../../../generated/graphql/graphql";
import {processQueryError} from "../../../service/ErrorService";
import {AppDataContext} from "../../../AppData";
import {userFullName} from "../../../service/UserService";
import AppIconButton from "../buttons/AppIconButton";
import YesNoBooleanLabel from "../text/YesNoBooleanLabel";
import PaginationSection from "../tables/PaginationSection";
import {kTopicUsers} from "../../../core/constants";
import TableHeadCell from "../tables/TableHeadCell";
import {IconBrain} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    photoUrl: {
        width: 48,
        height: 'auto',
    },
    body: {
        wordBreak: 'break-word',
        '.normal': {
            wordBreak: 'normal',
        },
    },
}));

const kUsersPageSize = 10;

export interface IUsersTableProps {
    search?: string;
    onToggleSuperAdmin?: (user: UserResponse, superAdmin: boolean) => void;
}

/**
 * Table component for displaying User list.
 */
export default function UsersTable(props: IUsersTableProps) {
    const {search, onToggleSuperAdmin} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();
    const {classes: generalClasses} = useGeneralStyles();

    const [sortDesc, setSortDesc] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('id');

    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AdminUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicUsers,
            {
                uri: '/admin/user/search',
                params: {
                    search,
                    page,
                    pageSize: kUsersPageSize,
                    sortDesc,
                    sortBy,
                } as AdminGetUsersInput,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            () => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [page, search, sortDesc, sortBy]);

    useEffect(() => {
        if (data) {
            setTotal(data.total);
        } else {
            setTotal(0);
        }
    }, [data]);

    const [rowsJSX, setRowsJSX] = useState<ReactNode[]>([]);
    useEffect(() => {
        setRowsJSX(
            data ? data.content.map((user) => {
                return (
                    <tr key={user.id}>
                        <td className="normal">{user.id}</td>
                        <td>{user.firebaseId}</td>
                        <td>{user.companyIds}</td>
                        <td>
                            {user.photoUrl ? (
                                <a target="_blank" href={user.photoUrl}>
                                    <img
                                        className={classes.photoUrl}
                                        src={user.photoUrl}
                                        alt={tt('usersTable.header.photoUrl')}
                                    />
                                </a>
                            ) : undefined}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.loginProviders.join(', ')}</td>
                        <td>{userFullName(user.name, user.surname)}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.contactEmail}</td>
                        <td>{user.language ? languageToString(user.language) : undefined}</td>
                        <td>
                            <YesNoBooleanLabel
                                value={user.superAdmin}
                            />
                        </td>
                        <td align="right">
                            <Flex
                                justify="flex-end"
                            >
                                <AppIconButton
                                    onClick={() => onToggleSuperAdmin && onToggleSuperAdmin(user, !user.superAdmin)}
                                    tooltip={user.superAdmin ? tt('common.table.button.removeSuperAdmin') : tt('common.table.button.addSuperAdmin')}
                                >
                                    <IconBrain color={user.superAdmin ? 'red' : 'green'}/>
                                </AppIconButton>
                            </Flex>
                        </td>
                    </tr>
                );
            }) : []
        );
    }, [data]);

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
                <thead>
                <tr>
                    <TableHeadCell
                        sortKey="id"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('common.table.header.id')}
                    </TableHeadCell>
                    <th>{tt('usersTable.header.firebaseId')}</th>
                    <th>{tt('usersTable.header.companyIds')}</th>
                    <th>{tt('usersTable.header.photoUrl')}</th>
                    <TableHeadCell
                        sortKey="email"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('usersTable.header.email')}
                    </TableHeadCell>
                    <th>{tt('usersTable.header.loginProviders')}</th>
                    <TableHeadCell
                        sortKey="surname"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('usersTable.header.fullName')}
                    </TableHeadCell>
                    <th>{tt('usersTable.header.phoneNumber')}</th>
                    <th>{tt('usersTable.header.contactEmail')}</th>
                    <th>{tt('usersTable.header.language')}</th>
                    <TableHeadCell
                        sortKey="superAdmin"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('usersTable.header.superAdmin')}
                    </TableHeadCell>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', data?.total || 0)}
                    </Box>
                </tr>
                </thead>

                <tbody
                    className={classes.body}
                >
                {rowsJSX}
                </tbody>

                <tfoot>
                <tr>
                    <TableHeadCell
                        sortKey="id"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('common.table.header.id')}
                    </TableHeadCell>
                    <th>{tt('usersTable.header.firebaseId')}</th>
                    <th>{tt('usersTable.header.companyIds')}</th>
                    <th>{tt('usersTable.header.photoUrl')}</th>
                    <TableHeadCell
                        sortKey="email"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('usersTable.header.email')}
                    </TableHeadCell>
                    <th>{tt('usersTable.header.loginProviders')}</th>
                    <TableHeadCell
                        sortKey="surname"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('usersTable.header.fullName')}
                    </TableHeadCell>
                    <th>{tt('usersTable.header.phoneNumber')}</th>
                    <th>{tt('usersTable.header.contactEmail')}</th>
                    <th>{tt('usersTable.header.language')}</th>
                    <TableHeadCell
                        sortKey="superAdmin"
                        sortDesc={sortDesc}
                        setSortDesc={setSortDesc}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    >
                        {tt('usersTable.header.superAdmin')}
                    </TableHeadCell>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', data?.total || 0)}
                    </Box>
                </tr>
                </tfoot>
            </Table>

            <PaginationSection
                total={total}
                page={page}
                setPage={setPage}
                pageSize={kUsersPageSize}
            />
        </>
    );
}

import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import {AuthUserSignIn, AuthUserSignInSocialRedirectResult} from "../../../service/AuthUserService";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import LogoWithText from "../../components/LogoWithText";
import {createStyles, Divider, PasswordInput, Space, TextInput} from "@mantine/core";
import {isEmail, useForm} from "@mantine/form";
import {LoginProvider} from "../../../generated/graphql/graphql";
import AppButton from "../../components/buttons/AppButton";
import {kDefaultWaitForMillis, WaitFor} from "../../../utils/DateUtils";
import {kDashboardRoute} from "../dashboard/DashboardScreen";
import {useNavigate} from "react-router-dom";
import {IconBrandGoogle, IconLogin} from "@tabler/icons-react";

export const kSignInRoute = '/sign-in';

const useStyles = createStyles((theme) => ({}));

export interface ISignInFormValues {
    email: string;
    password: string;
}

/**
 * Screen component for signIn to the Firebase auth.
 */
export default function SignInScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle, auth} = appDataContext;
    const theAuthUser = auth?.authUser;

    const navigate = useNavigate();
    const {classes} = useStyles();

    const form = useForm<ISignInFormValues>({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (value) => value && isEmail(value) ? null : tt('error.isNotValidEmail'),
            password: (value) => value && value.length >= 6 ? null : tt('error.isNotValidPassword'),
        },
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(tt('signIn.screen.title'));
    }, []);

    useEffect(() => {
        AuthUserSignInSocialRedirectResult();
    }, []);

    /**
     * SignIn with Firebase Auth.
     */
    const signIn = async (provider: LoginProvider, values?: ISignInFormValues) => {
        setLoading(true);

        await WaitFor(kDefaultWaitForMillis);

        await AuthUserSignIn(
            appDataContext,
            provider,
            values,
        );

        setLoading(false);
    };

    useEffect(() => {
        if (theAuthUser && theAuthUser.signInResponse && !theAuthUser.signInResponse.error && theAuthUser.signInResponse.data) {
            navigate(kDashboardRoute);
        }
    }, [theAuthUser]);

    return (
        <ScreenContent
            appBar={false}
            navigationDrawer={false}
        >
            <AppPaper>
                <LogoWithText/>

                <Space h="sm"/>

                <form
                    onSubmit={form.onSubmit((values) => signIn(LoginProvider.Email, values))}
                >
                    <TextInput
                        label={tt('signIn.screen.email.label')}
                        placeholder={tt('signIn.screen.email.placeholder')}
                        mb="sm"
                        {...form.getInputProps('email')}
                    />

                    <PasswordInput
                        label={tt('signIn.screen.password.label')}
                        placeholder={tt('signIn.screen.password.placeholder')}
                        mb="sm"
                        {...form.getInputProps('password')}
                    />

                    <AppButton
                        loading={loading}
                        type="submit"
                        fullWidth={true}
                        mb="sm"
                        leftIcon={<IconLogin/>}
                    >
                        {tt('signIn.screen.signInWithEmail')}
                    </AppButton>

                    <Divider mb="sm" variant="solid"/>

                    <AppButton
                        loading={loading}
                        type="button"
                        fullWidth={true}
                        mb="sm"
                        leftIcon={<IconBrandGoogle/>}
                        onClick={() => signIn(LoginProvider.Google)}
                    >
                        {tt('signIn.screen.signInWithGoogle')}
                    </AppButton>
                </form>
            </AppPaper>
        </ScreenContent>
    );
}
